import React, { useState, useContext, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios, { setToken } from '../../../../my-axios';
import UserContext, { getToken } from '../../../../contexts/UserContext';
import ContentContext from '../../../../contexts/ContentContext';
import { Form, Dropdown, Button } from 'react-bootstrap';
import MsgBox from '../../../controls/MsgBox';
import Spinner from '../../../controls/Spinner';
import PopupPage from '../../../controls/PopupPage';
import { getErrorDesc, date2str } from '../../../../utils/common';

const URL = '/users';
const initialState = {
    msg: null,
    loading: false,
    changing: false,
};

const initialUser = {
    id: null,
    firstname: '',
    surname: '',
    email: '',
    role: 'RECEPTIONIST',
    state: '',
    status: 0
};

const roleList = [
    {
        code: 'ADMIN',
        name: 'ADMIN'
    },
    {
        code: 'RECEPTIONIST',
        name: 'RECEPTIONIST'
    }
]

export default function UserDetail() {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const contentCtx = useContext(ContentContext);

    const { id } = useParams();
    const [state, setState] = useState(initialState);
    const [user, setUser] = useState(initialUser);
    const [oldUser, setOldUser] = useState(initialUser);
    const [showPopup, setShowPopup] = useState(false);
    const [newStatus, setNewStatus] = useState(0);
    const [saveConfirm, setSaveConfirm] = useState(false);
    const [removeConfirm, setRemoveConfirm] = useState(false);

    useEffect(() => {
        contentCtx.updatePath([
            {
                title: 'Users',
                link: '/users'
            },
            {
                title: 'User Detail',
                link: `/users/${id}`
            }]);
        if (id)
            loadData(id);
    }, [id]);

    const loadData = (id) => {
        setState({ ...state, loading: true, msg: null });
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        axios.get(`${URL}/${id}`).then((result) => {
            console.log("GetFromServer", result.data);
            setUser({ ...result.data });
            setOldUser({ ...result.data });
            setState({ ...state, loading: false, msg: null, changing: false });
        }).catch((error) => {
            console.log('error:', error);
            if (error.response && error.response.status == 401) {
                navigate("/signout");
            }
            else {
                setUser(initialUser);
                setState({
                    ...state,
                    loading: false, msg: getErrorDesc(error)
                });
            }
        })
    }

    const changeRole = (role) => {
        console.log("changeRole", role);
        changeValue('role', role)
    }

    const changeValue = (field, value) => {
        console.log("changeValue of " + field, value);
        setState({ ...state, msg: null, changing: true })
        setUser({ ...user, [field]: value });
    }

    const handleStatus = (status) => {
        console.log("To change status: ", status);
        setNewStatus(status);
        setShowPopup(true);
    }

    const changeStatus = () => {
        setShowPopup(false);
        if (user && user.id) {
            var data = {
                uid: user.id
            }
            var statusUrl = newStatus === 1 ? "/user/activateuser" : "/user/deactivateuser";

            setState({ ...state, loading: true, msg: null });
            axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
            axios.post(statusUrl, data).then((result) => {
                console.log(statusUrl + ": ", result.data);
                loadData(user.id);
                setState({ ...state, loading: false, msg: null });
            }).catch((error) => {
                console.log('activateuser error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error)
                    });
                }
            })
        }
    }

    const save = () => {
        setShowPopup(false);
        setState({ ...state, loading: true, msg: null });
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        if (user && user.id) {
            // update
            axios.put(URL, user).then((result) => {
                console.log("Update user: ", result.data);
                loadData(user.id);
                setState({ ...state, loading: false, msg: null, changing: false });
            }).catch((error) => {
                console.log('UpdateUser error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error)
                    });
                }
            })
        }
        else {
            // insert
            axios.post(URL, user).then((result) => {
                console.log("Insert User: ", result.data);
                axios.post("/user/activateuser", { uid: result.data.data }).then((result2) => {
                    console.log("Activate User: ", result2.data);
                    loadData(result.data.data);
                    setState({ ...state, loading: false, msg: null, changing: false });
                }).catch((error2) => {
                    loadData(result.data.data);
                    console.log('ActivateUser error:', error2);
                    if (error2.response && error2.response.status == 401) {
                        navigate("/signout");
                    }
                    else {
                        setState({
                            ...state,
                            loading: false, msg: getErrorDesc(error2)
                        });
                    }
                });
            }).catch((error) => {
                console.log('InsertUser error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error)
                    });
                }
            })
        }
    }

    const handleBack = () => {
        setUser(initialUser);
        setState({ ...state, changing: false });
        navigate(-1);
    };

    const remove = () => {
        setRemoveConfirm(false);
        setState({ ...state, loading: true, msg: null });
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        if (user && user.id) {
            console.log('delete id', user.id)
            // delete
            axios.delete(`${URL}/${user.id}`).then((result) => {
                console.log("Delete user: ", result.data);
                setState({ ...state, loading: false, msg: null });
                navigate("/users");
            }).catch((error) => {
                console.log('DeleteUser error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error)
                    });
                }
            })
        }
    }

    const showRemove = () => {
        setRemoveConfirm(true);
        setState({ ...state, changing: false });
    }

    const add = () => {
        setUser(initialUser);
        setState({ ...state, changing: false });
    }

    const cancel = () => {
        console.log("cancel");
        setState({ ...state, changing: false });
        setUser(oldUser);
    }
    return (

        <div>
            <div className="card-body">
                <Spinner value={state.loading} />

                <form role="form " >
                    <div className="row">
                        <div className="col">
                            <MsgBox value={{ type: 'error', title: '', msg: state.msg }} />
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col mr-3">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repName" className="font-weight-normal">First Name</label>
                                        <input id="repName" type="text" className="form-control" value={user.firstname} onChange={(e) => changeValue('firstname', e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group ">
                                        <label htmlFor="repName" className="font-weight-normal">Surname</label>
                                        <input id="repName" type="text" className="form-control" value={user.surname} onChange={(e) => changeValue('surname', e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col mr-3">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repEmailt" className="font-weight-normal">Email</label>
                                        <input id="repEmail" type="text" className="form-control" value={user.email} onChange={(e) => changeValue('email', e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label htmlFor="repState" className="font-weight-normal">State</label>
                                    <input id="repState" type="text" className="form-control" value={user.state} onChange={(e) => changeValue('state', e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repD" className="font-weight-normal">Role</label>
                                        <Dropdown onSelect={changeRole}>
                                            <Dropdown.Toggle id="basicRole-basic" >
                                                {user.role}
                                            </Dropdown.Toggle >
                                            <Dropdown.Menu>
                                                {
                                                    roleList.map((c) => {
                                                        return (<Dropdown.Item key={c.code} eventKey={c.code}>{c.name}</Dropdown.Item>)
                                                    })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repStatus" className="font-weight-normal">Status</label>
                                        <input id="repStatus" type="text" className="form-control" value={user.status === 1 ? 'ACTIVE' : user.status === 2 ? 'INACTIVE' : user.status === 0 ? 'NEW' : 'OTHER'} readOnly />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col text-left'>
                            <Button 
                                variant="secondary" 
                                onClick={handleBack} 
                                style={{ width: '100px', marginRight: '10px' }}>
                                Back
                            </Button>
                            {user && user.id
                                ? user.status !== 2
                                    ? <Button style={{ width: '100px' }} variant="warning" onClick={() => handleStatus(2)}> Deactivate</Button> : <></> : <></>} <></>
                            {user && user.id
                                ? user.status !== 1 ? <Button style={{ width: '100px' }} variant="success" onClick={() => handleStatus(1)}> Activate</Button> : <></> : <></>}
                        </div>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            {/* Save Button: Always Enabled */}
                            <Button 
                                style={{ width: '100px' }} 
                                onClick={save}>
                                Save
                            </Button>

                            {user && user.id && !state.changing ? (
                                /* Show Remove Button if user exists and no changes */
                                <Button 
                                    style={{ width: '100px' }} 
                                    variant="danger" 
                                    onClick={showRemove}>
                                    Remove
                                </Button>
                            ) : (
                                /* Show Cancel Button if no Remove and there are changes */
                                (state.changing || !user || !user.id) && (
                                    <Button 
                                        style={{ 
                                            width: '100px', 
                                            opacity: state.changing ? 1 : 0.5, 
                                            pointerEvents: state.changing ? 'auto' : 'none' 
                                        }} 
                                        variant="secondary" 
                                        onClick={cancel}>
                                        Cancel
                                    </Button>
                                )
                            )}
                        </div>
                    </div>
                    <div className='row'>
                        <PopupPage
                            size="md"
                            openDialog={showPopup}
                            setOpenDialog={setShowPopup}
                            title={user.status !== 1 ? "Activate user" : "Deactivate user"}
                            label='Yes'
                            onSave={changeStatus}
                            labelCancel='No'>
                            <p>Are you sure to change this user's status?</p>
                        </PopupPage>
                        <PopupPage
                            size="md"
                            openDialog={saveConfirm}
                            setOpenDialog={setSaveConfirm}
                            title="User Registration"
                            label='Yes'
                            onSave={add}
                            labelCancel='No'>
                            <p> User Information has been successfully saved! </p><br /><p>Would you register a new recipient?</p>
                        </PopupPage>
                        <PopupPage
                            size="md"
                            openDialog={removeConfirm}
                            setOpenDialog={setRemoveConfirm}
                            title="Remove User Registration"
                            label='Yes'
                            onSave={remove}
                            labelCancel='No'>
                            <p> Are you sure to remove this user? </p>
                        </PopupPage>
                    </div>
                </form>
            </div >
        </div >
    )
}
