import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios from '../../../../my-axios';
import UserContext from '../../../../contexts/UserContext';
import ContentContext from '../../../../contexts/ContentContext';
import { Button } from 'react-bootstrap';
import MsgBox from '../../../controls/MsgBox';
import Spinner from '../../../controls/Spinner';
import PopupPage from '../../../controls/PopupPage';
import { getErrorDesc } from '../../../../utils/common';

const API_URL = '/generalInformation/doctors';
const URL = '/doctors';
const initialState = {
    msg: null,
    loading: false,
    changing: false,
};
const initialUser = {
    id: null,
    name: '',
    gid: '',
    website: '',
    fax: '',
    expertise: '',
    address: '',
    firstname: '',
    surname: '',
    email: '',
    role: 'RECEPTIONIST',
    state: '',
    status: 0
};
const roleList = [
    {
        code: 'ADMIN',
        name: 'ADMIN'
    },
    {
        code: 'RECEPTIONIST',
        name: 'RECEPTIONIST'
    }
];

export default function DoctorDetail() {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const contentCtx = useContext(ContentContext);

    const { id } = useParams();
    const [state, setState] = useState(initialState);
    const [user, setUser] = useState(initialUser);
    const [oldUser, setOldUser] = useState(initialUser);
    const [showPopup, setShowPopup] = useState(false);
    const [newStatus, setNewStatus] = useState(0);
    const [saveConfirm, setSaveConfirm] = useState(false);
    const [removeConfirm, setRemoveConfirm] = useState(false);

    useEffect(() => {
        contentCtx.updatePath([
            {
                title: 'Doctors',
                link: '/doctors'
            },
            {
                title: 'Doctor Details',
                link: `/doctors/${id}`
            }]);
        if (id)
            loadData(id);
    }, [id]);

    // useEffect(() => {
    //     if (id) {
    //         contentCtx.updatePath([
    //             {
    //                 title: 'Doctors',
    //                 link: '/doctors'
    //             },
    //             {
    //                 title: 'Doctor Details',
    //                 link: `/doctors/${id}`
    //             }
    //         ]);
    //         loadData(id);
    //     } else {
    //         contentCtx.updatePath([
    //             {
    //                 title: 'Doctors',
    //                 link: '/doctors'
    //             },
    //             {
    //                 title: 'Create Doctor',
    //                 link: '/doctors/new'
    //             }
    //         ]);
    //     }
    // }, [id]);


    const loadData = (id) => {
        setState({ ...state, loading: true, msg: null });
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        axios.get(`${API_URL}/${id}`).then((result) => {
            console.log("GetFromServer", result.data);
            setUser({ ...result.data });
            setOldUser({ ...result.data });
            setState({ ...state, loading: false, msg: null, changing: false });
        }).catch((error) => {
            console.log('error:', error);
            if (error.response && error.response.status == 401) {
                navigate("/signout");
            }
            else {
                setUser(initialUser);
                setState({
                    ...state,
                    loading: false, msg: getErrorDesc(error)
                });
            }
        });
    };

    const changeValue = (field, value) => {
        console.log("changeValue of " + field, value);
        setState({ ...state, msg: null, changing: true });
        setUser({ ...user, [field]: value });
    };


    const save = () => {
        setShowPopup(false);
        setState({ ...state, loading: true, msg: null });
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        if (user && user.id) {
            // update
            axios.put(API_URL, user).then((result) => {
                console.log("Update user: ", result.data);
                loadData(user.id);
                setState({ ...state, loading: false, msg: null, changing: false });
            }).catch((error) => {
                console.log('UpdateUser error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error)
                    });
                }
            });
        }
        else {
            // insert
            axios.post(API_URL, user).then((result) => {
                console.log("Insert User: ", result.data);
                loadData(result.data.data);
                setState({ ...state, loading: false, msg: null, changing: false });
            }).catch((error) => {
                console.log('InsertUser error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error)
                    });
                }
            });
        }
    };

    const remove = () => {
        setRemoveConfirm(false);
        setState({ ...state, loading: true, msg: null });
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        if (user && user.id) {
            console.log('delete id', user.id);
            // delete
            axios.delete(`${API_URL}/${user.id}`).then((result) => {
                console.log("Delete doctor: ", result.data);
                setState({ ...state, loading: false, msg: null });
                navigate("/doctors");
            }).catch((error) => {
                console.log('DeleteUser error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error)
                    });
                }
            });
        }
    };

    const showRemove = () => {
        setRemoveConfirm(true);
        setState({ ...state, changing: false });
    };

    const add = () => {
        setUser(initialUser);
        setState({ ...state, changing: false });
    };

    const handleBack = () => {
        setUser(initialUser);
        setState({ ...state, changing: false });
        navigate(-1);
    };

    const cancel = () => {
        console.log("cancel");
        setState({ ...state, changing: false });
        setUser(oldUser);
    };
    return (

        <div>
            <div className="card-body">
                <Spinner value={state.loading} />

                <form role="form " >
                    <div className="row">
                        <div className="col">
                            <MsgBox value={{ type: 'error', title: '', msg: state.msg }} />
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col mr-3">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repName" className="font-weight-normal">Name</label>
                                        <input id="repName" type="text" className="form-control" value={user.name} onChange={(e) => changeValue('name', e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group ">
                                        <label htmlFor="repName" className="font-weight-normal">Expertise</label>
                                        <input id="repName" type="text" className="form-control" value={user.expertise} onChange={(e) => changeValue('expertise', e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group ">
                                        <label htmlFor="repName" className="font-weight-normal">Contact</label>
                                        <input id="repName" type="text" className="form-control" value={user.contact} onChange={(e) => changeValue('contact', e.target.value)} />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repEmailt" className="font-weight-normal">Fax</label>
                                        <input id="repEmail" type="text" className="form-control" value={user.fax} onChange={(e) => changeValue('fax', e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repEmailt" className="font-weight-normal">ID FROM GENIE (gid)</label>
                                        <input id="repEmail" type="text" className="form-control" value={user.gid} onChange={(e) => changeValue('gid', e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repEmailt" className="font-weight-normal">Email</label>
                                        <input id="repEmail" type="text" className="form-control" value={user.email} onChange={(e) => changeValue('email', e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repEmailt" className="font-weight-normal">Address</label>
                                        <input id="repEmail" type="text" className="form-control" value={user.address} onChange={(e) => changeValue('address', e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repName" className="font-weight-normal">Website</label>
                                        <input id="repName2" type="text" className="form-control" value={user.website} onChange={(e) => changeValue('website', e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            {/* Back Button on the Left */}
                            <Button
                                variant="secondary"
                                onClick={handleBack}
                                style={{ width: '100px' }}>
                                Back
                            </Button>

                            {/* Right Buttons (Save, Cancel/Remove) */}
                            <div style={{ display: 'flex', gap: '10px' }}>
                                {/* Save Button: Always Enabled */}
                                <Button
                                    style={{ width: '100px' }}
                                    onClick={save}>
                                    Save
                                </Button>


                                {user && user.id && !state.changing ? (
                                    /* Show Remove Button if user exists and no changes */
                                    <Button
                                        style={{ width: '100px' }}
                                        variant="danger"
                                        onClick={showRemove}>
                                        Remove
                                    </Button>
                                ) : (
                                    /* Show Cancel Button if no Remove and there are changes */
                                    (state.changing || !user || !user.id) && (
                                        <Button
                                            style={{
                                                width: '100px',
                                                opacity: state.changing ? 1 : 0.5,
                                                pointerEvents: state.changing ? 'auto' : 'none'
                                            }}
                                            variant="secondary"
                                            onClick={cancel}>
                                            Cancel
                                        </Button>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <PopupPage
                            size="md"
                            openDialog={removeConfirm}
                            setOpenDialog={setRemoveConfirm}
                            title="Remove doctor"
                            label='Yes'
                            onSave={remove}
                            labelCancel='No'>
                            <p> Are you sure to remove this doctor? </p>
                        </PopupPage>
                    </div>
                </form>
            </div >
        </div >
    );
}