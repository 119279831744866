export function getErrorDesc(error) {
    let desc = '';
    let err = error.response;
    if (err) {
        if (err.status) desc = '[' + err.status + '] ';
        if (err.statusText) desc = desc + err.statusText + ' ';
        if (err.data && err.data.message) desc = desc + " - " + err.data.message;

    }
    else {
        desc = error.message;
    }
    return desc;
}

export function getDate() {
    return date2str(new Date());//.split('T')[0];
}

export function date2str(dateVal) {
    let date = new Date(dateVal);
    let formattedDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
    return formattedDate;
}

export function dateTime2str(dateTime) {
    let formattedDate = dateTime;
    try {
        let date = new Date(dateTime);

        // Format the date as desired
        formattedDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;
    }
    catch { }

    return formattedDate;
}

/**
 * Converts an ISO 8601 date-time string to a formatted date-time string in UTC.
 * 
 * @param {string} isoString - The ISO 8601 date-time string to be converted.
 * @param {boolean} [dateOnly=false] - A flag to return only the date portion.
 * @returns {string} The formatted date-time string in 'YYYY-MM-DD HH:MM:SS' format or just 'YYYY-MM-DD' if dateOnly is true.
 */
export function formatDateTime(isoString, dateOnly=false) {
    const date = new Date(isoString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');

    if (dateOnly) {
        return `${year}-${month}-${day}`;
    }
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function getFullName(r) {
    if (r)
        return r.firstname + " " + (r.middlename && r.middlename != null ? r.middlename + " " : "") + r.surname;
    else
        return '';
}