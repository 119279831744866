import { Modal, Button } from 'react-bootstrap';
import './activationModal.css';
import { FaCircleXmark, FaCircleInfo } from "react-icons/fa6";

const ActivationModal = ({ isModalOpen, selectedClinic, cancelToggle, confirmToggle }) => {
    return (
        <Modal
            show={isModalOpen}
            onHide={cancelToggle}
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title>
                    {selectedClinic?.status === 'ACTIVE' ? <FaCircleXmark className='deactivate-icon' /> : <FaCircleInfo className='activate-icon' color='#03a9f4' />}
                    {selectedClinic?.status === 'ACTIVE' ? 'Deactivating account' : 'Activating account'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* The <span className='clinic-name'>{selectedClinic?.name}</span>'s account will be <span className="deactivate">{selectedClinic?.status === 'ACTIVE' ? 'deactivated' : 'activated'}</span>! */}
                The <span className="clinic-name">{selectedClinic?.name}</span>'s account will be{' '}
                <span className={selectedClinic?.status === 'ACTIVE' ? 'deactivate' : 'activate'}>
                    {selectedClinic?.status === 'ACTIVE' ? 'deactivated!' : 'activated!'}
                </span>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={cancelToggle}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={confirmToggle}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ActivationModal;