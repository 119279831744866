import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios, { setToken } from '../../../../my-axios';
import UserContext, { getToken } from '../../../../contexts/UserContext';
import ContentContext from '../../../../contexts/ContentContext';
import { Form, Dropdown, Button } from 'react-bootstrap';
import MsgBox from '../../../controls/MsgBox';
import Spinner from '../../../controls/Spinner';
import PopupPage from '../../../controls/PopupPage';
import { getErrorDesc, date2str } from '../../../../utils/common';

const API_URL = "/generalInformation/pathologies";
const URL = '/pathologies';
const initialState = {
    msg: null,
    loading: false,
    changing: false,
};
const initialUser = {
    id: null,
    name: '',
    contact: '',
    website: '',
    fax: '',
    expertise: '',
    address: '',
    type: '',
    email: '',
    openingTime: '', // Added opening time field
    closingTime: '', // Added closing time field
};

export default function UserDetail() {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const contentCtx = useContext(ContentContext);

    const { id } = useParams();
    const [state, setState] = useState(initialState);
    const [user, setUser] = useState(initialUser);
    const [oldUser, setOldUser] = useState(initialUser);
    const [showPopup, setShowPopup] = useState(false);
    const [newStatus, setNewStatus] = useState(0);
    const [saveConfirm, setSaveConfirm] = useState(false);
    const [removeConfirm, setRemoveConfirm] = useState(false);

    useEffect(() => {
        contentCtx.updatePath([
            {
                title: 'Pathologies',
                link: '/pathologies'
            },
            {
                title: 'Pathology Details',
                link: `/pathologies/${id}`
            }
        ]);
        if (id)
            loadData(id);
    }, [id]);

    const loadData = (id) => {
        setState({ ...state, loading: true, msg: null });
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        axios.get(`${API_URL}/${id}`).then((result) => {
            console.log("GetFromServer", result.data);
            setUser({ ...result.data });
            setOldUser({ ...result.data });
            setState({ ...state, loading: false, msg: null, changing: false });
        }).catch((error) => {
            console.log('error:', error);
            if (error.response && error.response.status === 401) {
                navigate("/signout");
            }
            else {
                setUser(initialUser);
                setState({
                    ...state,
                    loading: false, msg: getErrorDesc(error)
                });
            }
        });
    };

    const changeValue = (field, value) => {
        console.log("changeValue of " + field, value);
        setState({ ...state, msg: null, changing: true });
        setUser({ ...user, [field]: value });
    };

    const save = () => {
        setShowPopup(true);
        setState({ ...state, loading: true, msg: null });
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        if (user && user.id) {
            // update
            axios.put(API_URL, user).then((result) => {
                console.log("Update user: ", result.data);
                loadData(user.id);
                setState({ ...state, loading: false, msg: null, changing: false });
            }).catch((error) => {
                console.log('UpdateUser error:', error);
                if (error.response && error.response.status === 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error)
                    });
                }
            });
        }
        else {
            // insert
            axios.post(API_URL, user).then((result) => {
                console.log("Insert User: ", result.data);
                loadData(result.data.data);
                setState({ ...state, loading: false, msg: null, changing: false });
            }).catch((error) => {
                console.log('InsertUser error:', error);
                if (error.response && error.response.status === 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error)
                    });
                }
            });
        }
    };

    const remove = () => {
        setRemoveConfirm(false);
        setState({ ...state, loading: true, msg: null });
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        if (user && user.id) {
            console.log('delete id', user.id);
            // delete
            axios.delete(`${API_URL}/${user.id}`).then((result) => {
                console.log("Delete pathology: ", result.data);
                setState({ ...state, loading: false, msg: null });
                navigate("/pathologies");
            }).catch((error) => {
                console.log('DeleteUser error:', error);
                if (error.response && error.response.status === 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error)
                    });
                }
            });
        }
    };

    const showRemove = () => {
        setRemoveConfirm(true);
        setState({ ...state, changing: false });
    };

    const add = () => {
        setUser(initialUser);
        setState({ ...state, changing: false });
    };

    const handleBack = () => {
        setUser(initialUser);
        setState({ ...state, changing: false });
        navigate(-1);
    };

    const cancel = () => {
        console.log("cancel");
        setState({ ...state, changing: false });
        setUser(oldUser);
    };

    return (
        <div>
            <div className="card-body">
                <Spinner value={state.loading} />

                <form role="form">
                    <div className="row">
                        <div className="col">
                            <MsgBox value={{ type: 'error', title: '', msg: state.msg }} />
                        </div>
                    </div>
                    <div className="row">
                        {/* Left Column */}
                        <div className="col mr-3">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repName" className="font-weight-normal">Name</label>
                                        <input
                                            id="repName"
                                            type="text"
                                            className="form-control"
                                            value={user.name}
                                            onChange={(e) => changeValue('name', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repContact" className="font-weight-normal">Contact</label>
                                        <input
                                            id="repContact"
                                            type="text"
                                            className="form-control"
                                            value={user.contact}
                                            onChange={(e) => changeValue('contact', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repFax" className="font-weight-normal">Fax</label>
                                        <input
                                            id="repFax"
                                            type="text"
                                            className="form-control"
                                            value={user.fax}
                                            onChange={(e) => changeValue('fax', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* New Time Pickers for Opening and Closing Times */}
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repOpeningTime" className="font-weight-normal">Opening Time</label>
                                        <input
                                            id="repOpeningTime"
                                            type="time"
                                            className="form-control"
                                            value={user.openingTime}
                                            onChange={(e) => changeValue('openingTime', e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repClosingTime" className="font-weight-normal">Closing Time</label>
                                        <input
                                            id="repClosingTime"
                                            type="time"
                                            className="form-control"
                                            value={user.closingTime}
                                            onChange={(e) => changeValue('closingTime', e.target.value)}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* Right Column */}
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repAddress" className="font-weight-normal">Address</label>
                                        <input
                                            id="repAddress"
                                            type="text"
                                            className="form-control"
                                            value={user.address}
                                            onChange={(e) => changeValue('address', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repWebsite" className="font-weight-normal">Website</label>
                                        <input
                                            id="repWebsite"
                                            type="text"
                                            className="form-control"
                                            value={user.website}
                                            onChange={(e) => changeValue('website', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* New Email Address Field */}
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repEmailAddress" className="font-weight-normal">Email Address</label>
                                        <input
                                            id="repEmailAddress"
                                            type="email"
                                            className="form-control"
                                            value={user.email}
                                            onChange={(e) => changeValue('email', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            {/* Back Button */}
                            <Button
                                variant="secondary"
                                onClick={handleBack}
                                style={{ width: '100px' }}
                            >
                                Back
                            </Button>

                            {/* Save/Cancel/Remove Buttons */}
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <Button
                                    style={{ width: '100px' }}
                                    onClick={save}
                                >
                                    Save
                                </Button>

                                {user && user.id && !state.changing ? (
                                    <Button
                                        style={{ width: '100px' }}
                                        variant="danger"
                                        onClick={showRemove}
                                    >
                                        Remove
                                    </Button>
                                ) : (
                                    (state.changing || !user || !user.id) && (
                                        <Button
                                            style={{
                                                width: '100px',
                                                opacity: state.changing ? 1 : 0.5,
                                                pointerEvents: state.changing ? 'auto' : 'none'
                                            }}
                                            variant="secondary"
                                            onClick={cancel}
                                        >
                                            Cancel
                                        </Button>
                                    )
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <PopupPage
                            size="md"
                            openDialog={removeConfirm}
                            setOpenDialog={setRemoveConfirm}
                            title="Remove pathology"
                            label='Yes'
                            onSave={remove}
                            labelCancel='No'
                        >
                            <p>Are you sure to remove this pathology?</p>
                        </PopupPage>
                    </div>
                </form>
            </div>
        </div>
    );
}
